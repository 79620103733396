import React, { useState } from 'react';
import AccountSetupStep from 'components/AccountSetupStep/AccountSetupStep';
import './MicrositeHeaderFAQ.scss';
import 'remixicon/fonts/remixicon.css';
import BillingProcessFAQ from 'components/BillingProcessFAQ/BillingProcessFAQ';
import ConfigureSystemApp from 'components/ConfigureSystemApp/ConfigureSystemApp';
import {animateScroll as scroll } from 'react-scroll'
import PermitStations from "components/PermitStations/PermitStations";
import { useQueryParam, StringParam } from "use-query-params";


const MicrositeHeaderFAQ = ({ data }) => {

  const [page, showPage] = useState(0);
  const [nextSection, showNextSection] = useState(true);
  const [tab] = useQueryParam("tab", StringParam);

  const nextPage = (scrollView) => {
    showPage(page + 1);
    if (page == 0 || page == 2) {
      showNextSection(false);
    } else {
      showNextSection(true);
    }
    if(scrollView){
      scroll.scrollToTop();
    }
  };
 
  const handleNextPage = (e, i) => {
    e.preventDefault();
    showPage(i);
    if (i == 1 || i == 3) {
      showNextSection(false);
    } else {
      showNextSection(true);
    }
  };
 
  const previousPage = () => {
    showPage(page - 1);
    if (page == 2) {
      showNextSection(false);
    } else {
      showNextSection(true);
    }
  };
 
  const visibleNextButtonSection = () => {
    showNextSection(true);
  };
 
  const visiblePreviousButtonSection = () => {
    showPage(0);
    showNextSection(true);
  };

  const nextSectionVisible = () => {
    showPage(2);
    showNextSection(true);
    scroll.scrollToTop();
  };

  const AlarmPermitSteps = ({ data }) => {
    return (
      <>
      <div className="row">
        <div className="col-md-12">
        <div className="A3Heading">{data.title}</div>
        <AlarmPermitStep data={data.steps}></AlarmPermitStep>
      </div>
      </div>
      {<div className="row">
      <PermitStations></PermitStations>
      </div>}
      </>
    );
  };

  const AlarmPermitStep = ({ data }) => {
    return data.map(alarmstep => (
      <>
        <div className="A3Subheading">{alarmstep.title}</div>
        <div
          className="A3Text"
          dangerouslySetInnerHTML={{
            __html: alarmstep.step,
          }}
        />
      </>
    ));
  };

  const Tiles = ({ data, classes }) => {
    return data.map((tile, i) => (
      <a
        className={i == page ? 'active' : 'd-none d-lg-block'}
        key={i}
        onClick={e => handleNextPage(e, i)}
      >
        <div className="Tiles">
          <h1 className="TileNumber">{i + 1}</h1>
          <p className="TileTitle">{tile.name}</p>
          <i className={classes[i].name}></i>
          {i != 3 && <span className="Tile1Divider d-none d-lg-block"></span>}
          {tab == "obtain-alarm-permit" && showPage(2) }
        </div>
      </a>
    ));
  };

  const MobileTabs = ({ classes }) => {
    return classes.map((eachMobileTabClass, i) => (
      <>
        <a className={i == page ? 'active' : 'd-sm-block d-lg-none'} key={i} onClick={e => handleNextPage(e, i)}  >
          <i className={eachMobileTabClass.name}></i>
        </a>
        {i != 3 && (
          <span
            className="TileDivider d-sm-block d-lg-none"
            key={i + 10}
          ></span>
        )}
      </>
    ));
  };

  return (
    <>
      <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet" />
      <div className="A3ContentBox">
        <h1 className="Welcome">{data.welcomemessage.title}</h1>
        <h5 className="SubTitle">{data.welcomemessage.subtitle}</h5>
        <div className="A3MainTabs">
          <Tiles
            data={data.headerTileNames.names}
            classes={data.headerTileNames.classes}
          ></Tiles>
          <div className="A3MobileTabs">
            <MobileTabs
              classes={data.mobileTabsClassNames.classes}
            ></MobileTabs>
          </div>

          <button
            className="TileNavLeft d-sm-block d-lg-none"
            onClick={previousPage}
            disabled={page == 0}
          >
            <svg className="regular" viewBox="0 0 256 512"><path d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg>
          </button>
          <button
            className="TileNavRight d-sm-block d-lg-none"
            onClick={() => nextPage(false)}
            disabled={page == 3}
          >
            <svg className="regular" viewBox="0 0 256 512"><path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg>
          </button>
        </div>
      </div>
      <div className="A3Body">
        <div className="A3ContentBox">
          {page == 0 && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <div className="A3Heading">Create your Smart Home Security Account</div>
                </div>
              </div>
              <AccountSetupStep data={data.AccountSteps}></AccountSetupStep>
            </>
          )}

{page == 1 && (
            <ConfigureSystemApp
              data={data.solutions}
              panelsetup={data.panelsetup}
              systemAccountSteps={data.SystemAccountSteps}
              systemCameraSetupSteps={data.SystemCameraSetupSteps}
              systemAppSetupSteps={data.SystemAppSetupSteps}
              systemThermostatSteps={data.SystemThermostatSteps}
              systemThermostatScheduleSteps={data.SystemThermostatScheduleSteps}
              smartAssistantspanelsetup={data.SmartAssistantspanelsetup}
              smartassistantaccordion={data.smartassistantaccordion}
              nextButtonSection={visibleNextButtonSection}
              backButtonSection={visiblePreviousButtonSection}
              nextSectionVisible={nextSectionVisible}
            ></ConfigureSystemApp>
          )}

          {page == 2 && (
            <AlarmPermitSteps data={data.AlarmPermitSteps}></AlarmPermitSteps>
          )}

          {page == 3 && (
            <>
            <div className="row">
            <div className="col-md-12">
              <div className="A3Heading">Frequently Asked Questions on Billing Process</div>
            </div>
          </div>
            <BillingProcessFAQ data={data.billingProcess}></BillingProcessFAQ>
            </>
          )}
          {
            nextSection && (
            <div className="row">
                      <div className="col-md-12">
                        <div className={page != 0 ? 'A3Button' : 'A3ButtonFill'}>
                            <button
                              type="button"
                              className="A3btnFill"
                              onClick={() => nextPage(true)}
                            >
                              Next Section<i className="ri-arrow-right-line"></i>
                            </button>
                        </div>
                      </div>
                    </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default MicrositeHeaderFAQ;
