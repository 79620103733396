import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const BbillingProcessSteps = ({steps}) => {
  return steps.map((billingProcess, i) => (
    <>
<AccordionItem className="accordion-element" collapsed="false" key={i}>
<AccordionItemHeading>
    <AccordionItemButton className="accordion-element__row A3AccordianElement">
      <div className="accordion-element__row-header A3AccordianHeader">
        <div className="accordion-element__row-header__title A3AccordianTitle">{billingProcess.title}</div>
      </div>
      <div className="accordion-element__caret A3AccordianCaret">&rsaquo;</div>
    </AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel className="accordion-element__item-panel A3AccordianPanel">
        <div
          className="A3Text"
          dangerouslySetInnerHTML={{
            __html: billingProcess.step,
          }}
        />
</AccordionItemPanel>
</AccordionItem>
</>
  ));
};

export default function BillingProcessFAQ({ data }) {
  return (
    <>
      <Accordion allowMultipleExpanded={true}
            allowZeroExpanded={true}>
        <BbillingProcessSteps steps={data.steps}></BbillingProcessSteps>
      </Accordion>
    </>
  );
}
